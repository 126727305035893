<template>
  <div class="pick-user">
    <div class="pick-user__col">
      <SteamAvatar :src="image" :href="user.gameProfileLink" is-big />
    </div>

    <div class="pick-user__col">
      <div class="pick-user__username">
        <router-link
          :to="{
            name: 'profile',
            params: { hash: user.hash },
          }"
          target="_blank"
        >
          {{ userName }}
        </router-link>
      </div>
      <div class="pick-user__statistics">
        <span>
          {{ win }}
        </span>
        –
        <span>
          {{ lose }}
        </span>
        –
        <span>
          {{ draw }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import SteamAvatar from '@components/v2/match/pick/SteamAvatar.vue';

export default {
  name: 'PickUser',
  components: { SteamAvatar },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    image: {
      type: String,
      default: '',
    },
    userName: {
      type: String,
      default: '',
    },
    pvpHref: {
      type: String,
      default: '',
    },
    steamHref: {
      type: String,
      default: '',
    },
    win: {
      type: [String, Number],
      default: '',
    },
    lose: {
      type: [String, Number],
      default: '',
    },
    draw: {
      type: [String, Number],
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.pick-user {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;

  color: #f5f5f7;
  letter-spacing: 0.3px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;

  @include min-tablet() {
    gap: 12px;
    font-size: 18px;
    line-height: 26px;
  }
}

.pick-user__col {
  &:last-child {
    width: calc(100% - 40px);
    @include min-tablet() {
      width: calc(100% - 76px);
    }
  }
}

.pick-user__username {
  max-width: 100%;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  a {
    color: inherit;
    text-decoration: none;
  }
}

.pick-user__statistics {
  color: #979797;

  span:first-child {
    color: #65cb6d;
  }
  span:nth-child(2) {
    color: #c64040;
  }
}
</style>
