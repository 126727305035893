<template>
  <div class="edit-match-result">
    <h3 class="edit-match-result__header">
      Ваша очередь выставить результаты
    </h3>
    <Notification
      class="edit-match-result__notification"
      type="info"
      horizontal
      :title="savedMatchResultMessage"
    />

    <div class="edit-match-result__wrapper">
      <div v-if="!isScreenshotOnlySetScore">
        <h3 class="edit-match-result__header">
          Проставьте результат
        </h3>
        <div class="edit-match-result__scores">
          <p class="edit-match-result__name">{{ homeTeamName }}</p>
          <div class="edit-match-result__inputs">
            <Input
              v-model="resultPayload.pointsTeamOne"
              class="edit-match-result__input"
              size="regular"
            />
            <div class="edit-match-result__delimiter">:</div>
            <Input
              v-model="resultPayload.pointsTeamTwo"
              class="edit-match-result__input"
              size="regular"
            />
          </div>
          <p
            class="edit-match-result__name edit-match-result__name--align-right"
          >
            {{ visitorTeamName }}
          </p>
        </div>
      </div>

      <div class="edit-match-result__uploader">
        <FileUploader
          :title="uploaderTitle"
          :placeholder="uploaderPlaceholder"
          :placeholder-desktop="uploaderPlaceholderDesktop"
          :max-file-size="5"
          :warning-text="warningText"
          multiple
          @input="addFiles"
        />
      </div>
      <Button
        v-if="isVisibleSaveButton"
        long
        size="big"
        type="primary"
        :text="$t('save.single')"
        :loading="isLoading"
        @click="handleButtonClick"
      />
    </div>
  </div>
</template>

<script>
import Notification from '@components/v2/ui/Notification.vue';
import Input from '@components/v2/ui/Input.vue';
import FileUploader from '@components/v2/FileUploader.vue';
import Button from '@components/v2/ui/Button.vue';

export default {
  name: 'EditMatchResult',
  components: { Input, Button, Notification, FileUploader },
  props: {
    homeTeamName: {
      type: String,
      default: '',
    },
    visitorTeamName: {
      type: String,
      default: '',
    },
    warningText: {
      type: String,
      required: true,
    },
    uploaderTitle: {
      type: String,
      required: true,
    },
    uploaderPlaceholder: {
      type: String,
      required: true,
    },
    uploaderPlaceholderDesktop: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isVisibleSaveButton: {
      type: Boolean,
      default: true,
    },
    savedMatchResultMessage: {
      type: String,
      default: '',
    },
    isScreenshotOnlySetScore: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      resultPayload: {
        pointsTeamOne: null,
        pointsTeamTwo: null,
      },
    };
  },
  methods: {
    handleButtonClick() {
      this.$emit('saveResult', this.resultPayload);
    },
    addFiles(files) {
      this.$emit('addFiles', files);
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-match-result {
  margin-bottom: 16px;
}
.edit-match-result__header {
  color: #f5f5f7;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 24px;
}
.edit-match-result__notification {
  margin-bottom: 24px;
}
.edit-match-result__scores {
  display: grid;
  grid-template-columns: 1fr 120px 1fr;
  align-content: center;
  gap: 12px;
  margin-bottom: 16px;
}
.edit-match-result__inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.edit-match-result__input {
  width: 40px;
  height: 40px;
}
.edit-match-result__name--align-right {
  text-align: right;
}
.edit-match-result__delimiter {
  color: #f5f5f7;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
}
</style>
