<template>
  <div class="pick-card" :class="cardClass" @click="handleCardClick">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'PickCard',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cardClass() {
      return {
        'pick-card--disabled': this.disabled,
        'pick-card--default': !this.disabled,
      };
    },
  },
  methods: {
    handleCardClick() {
      if (this.disabled) return;
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.pick-card {
  padding: 12px 16px;
  border-radius: 12px;
  color: #f5f5f7;
  background: #2d2d30;

  &--default {
    &:hover {
      box-shadow: 0px 0px 40px 0px rgba(255, 255, 255, 0.25);
      background: #57575b;
    }
  }
}
</style>
