<template>
  <div class="hub-lobby-view">
    <div class="hub-lobby-view-container">
      <div class="hub-lobby-view__content">
        <div class="hub-lobby-view__label">
          <span
            class="hub-lobby-view__match-status"
            :class="`hub-lobby-view__match-status--${matchStatusLabel.status}`"
          >
            {{ matchStatusLabel.text }}
          </span>
        </div>
        <div class="hub-lobby-view__hub-name">
          <a :href="linkToHub" class="hub-lobby-view__hub-link">
            {{ hubName }}
          </a>
        </div>
        <MatchHeader
          v-if="homeTeam"
          :home-team="homeTeam"
          :visitor-team="visitorTeam"
          :home-team-score="homeTeamScore"
          :visitor-team-score="visitorTeamScore"
          :techical-defeat="isTechnicalDefeat"
        />
        <div class="hub-lobby-view__tabs">
          <TabListButtons
            v-model="value"
            :tabs="tabs"
            tab-key="title"
          />
        </div>
        <div
          class="hub-lobby-view__wrapper"
          :class="{
            'hub-lobby-view__wrapper--tabs-view':
              value.type === 'statistic',
          }"
        >
          <template v-if="value.type === 'match'">
            <template v-if="hubState === 'lobby'">
              <div class="hub-lobby-view__participants">
                <PickParticipants
                  :participants="participants"
                  :team-home="teams.home"
                  :team-visitor="teams.visitor"
                  :await-turn="isWaitPick"
                  :user-picking="currentPickingUser"
                  :is-big="true"
                  :deadline="
                    timeStore.getTimeWithDiff(pickTimeoutTimestamp)
                  "
                  @click="handlePlayersPickClick"
                />
              </div>
            </template>
            <template v-if="showInGameLobby">
              <HubManualCreateGameLobby>
                <template #header>
                  <p class="manual-lobby__header">
                    {{ manualLobbyHeader }}
                  </p>
                </template>
                <template #body>
                  <Notification
                    class="manual-lobby__notification"
                    type="info"
                    horizontal
                    :title="manualLobbyInfoBox"
                  >
                    <a
                      v-if="ingameLobbyAction === 'create'"
                      :href="`/tournament/${hubID}?tab=rules`"
                      target="_blank"
                    >
                      {{ $t('matches.link_to_game_rules') }}
                    </a>
                  </Notification>
                  <ClipboardInput
                    class="manual-lobby__body"
                    :label="$t('matches.lobby_name')"
                    copy-label="Скопировать"
                    :value="manualLobbyInfo.name"
                  />
                </template>
                <template
                  v-if="ingameLobbyAction === 'create'"
                  #footer
                >
                  <Button
                    type="primary"
                    size="big"
                    long
                    :text="$t('matches.confirm_create_lobby')"
                    @click="confirmCreateLobby"
                  ></Button>
                </template>
              </HubManualCreateGameLobby>
            </template>
            <template v-if="hubState === 'match'">
              <template v-if="isSetScore && !isTimeToShowSetScore">
                <div class="set-score">
                  <h3 class="set-score__title">
                    {{ $t('matches.wait_for_set_score') }}
                  </h3>
                  <div class="set-score__timer">
                    <Timer
                      :initial="
                        timeStore.getTimeWithDiff(tsSinceSetScore)
                      "
                      format="mm:ss"
                      countdown
                    />
                  </div>
                </div>
              </template>
              <EditMatchResult
                v-if="
                  isSetScore &&
                  isTimeToShowSetScore &&
                  canUserSetScore === true
                "
                :home-team-name="homeTeam?.name"
                :visitor-team-name="visitorTeam?.name"
                :warning-text="warningText"
                :uploader-title="uploaderTitle"
                :uploader-placeholder="uploaderPlaceholder"
                :uploader-placeholder-desktop="
                  uploaderPlaceholderDesktop
                "
                :is-loading="isLoading.update"
                :is-visible-save-button="isVisibleSaveButton"
                :saved-match-result-message="savedMatchResultMessage"
                :is-screenshot-only-set-score="
                  isScreenshotOnlySetScore
                "
                @addFiles="addFiles"
                @saveResult="saveResult"
              />
              <div
                v-if="
                  isSetScore &&
                  isTimeToShowSetScore &&
                  canUserSetScore === false
                "
                class="waiting-result"
              >
                <h3 class="waiting-result__title">
                  {{ $t('matches.waiting_result') }}
                </h3>
                <Notification
                  class="waiting-result__notification"
                  type="info"
                  horizontal
                  :title="$t('matches.match_result')"
                  :subtitle="$t('matches.match_waiting_result')"
                >
                </Notification>
                <a
                  class="waiting-result__link"
                  target="__blank"
                  :href="helpLink.href"
                >
                  {{ helpLink.text }}
                </a>
              </div>
              <PickbanHub
                v-if="isPickban"
                :match-id="matchId"
                :game-id="gameId"
                :game-code="gameCode"
                :user-picking="captainPickingMap"
                :is-show-server-is-loading="isShowServerIsLoading"
              />
            </template>
            <template v-if="serverState === 'server'">
              <div
                v-if="isWaitServerConfig"
                class="server-wait-message"
              >
                <h2 class="server-wait-message__title">
                  {{ $t('main.waitServerForMatch') }}
                </h2>
                <loader
                  class="server-wait-message__loader"
                  :size="32"
                />
              </div>
              <StartGame
                v-if="hasStartButton"
                :server="match.server"
                :is-cs-game="isCsGame"
                :game-code="game.code"
              />
            </template>
            <template
              v-if="
                (!isLoggedIn && !isFinishedMatch) ||
                (isLoggedInNotParticipantUser && !isFinishedMatch)
              "
            >
              <Notification
                class="waiting-result__notification"
                type="info"
                horizontal
                :title="$t('matches.match_is_going')"
              >
              </Notification>
            </template>
            <template v-if="isFinishedMatch">
              <MatchResult
                :status="matchResult"
                :button-text="'Перейти к хабу'"
                @click="goToHubPage"
              />
            </template>

            <div
              class="hub-lobby-view__team hub-lobby-view__team--home-team"
            >
              <h3
                v-if="homeMembers"
                class="hub-lobby-view__team-name hub-lobby-view__team-name--mobile"
              >
                {{ homeTeam.name }}
              </h3>
              <ParticipantsList
                v-if="homeMembers"
                :participants="homeMembers"
              />
            </div>
            <div class="hub-lobby-view__team">
              <h3
                v-if="visitorMembers"
                class="hub-lobby-view__team-name hub-lobby-view__team-name--mobile"
              >
                {{ visitorTeam.name }}
              </h3>
              <ParticipantsList
                v-if="visitorMembers"
                :participants="visitorMembers"
              />
            </div>
          </template>
          <template v-if="value.type === 'teams'">
            <div
              class="hub-lobby-view__team hub-lobby-view__team--home-team"
            >
              <h3
                v-if="homeTeam"
                class="hub-lobby-view__team-name hub-lobby-view__team-name--mobile"
              >
                {{ homeTeam.name }}
              </h3>
              <ParticipantsList :participants="homeMembers" />
            </div>
            <div class="hub-lobby-view__team">
              <h3
                v-if="homeTeam"
                class="hub-lobby-view__team-name hub-lobby-view__team-name--mobile"
              >
                {{ visitorTeam.name }}
              </h3>
              <ParticipantsList :participants="visitorMembers" />
            </div>
          </template>
          <template v-if="value.type === 'statistic'">
            <MatchStatisticTables :id="matchIdFromAddressBar" />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MatchHeader from '@components/v2/match/MatchHeader.vue';
import TabListButtons from '@components/v2/TabListButtons.vue';
import PickParticipants from '@components/v2/match/pick/PickParticipants.vue';
import ParticipantsList from '@components/v2/match/ParticipantsList.vue';
import MatchResult from '@components/v2/match/MatchResult.vue';
import ClipboardInput from '@components/v2/ui/ClipboardInput.vue';
import HubManualCreateGameLobby from '@src/views/Lobby/HubManualCreateGameLobby.vue';
import Button from '@components/v2/ui/Button.vue';
import Notification from '@components/v2/ui/Notification.vue';
import PickbanHub from '@components/Match/Common/Pickban/PickbanHub.vue';
import EditMatchResult from '@components/Match/Common/EditMatchResult.vue';
import MatchStatisticTables from '@components/Match/Common/MatchStatisticsTables.vue';
import StartGame from '@components/Match/Common/StartGame.vue';
import Timer from '@components/v2/Timer.vue';
import { getDateString } from '@utils/time.js';
import { i18n } from '@src/localization/config';
import { useTimeStore } from '@src/shared/store/useTimeStore';

export default {
  name: 'HubMatch',
  components: {
    Timer,
    StartGame,
    Button,
    MatchHeader,
    TabListButtons,
    PickParticipants,
    ParticipantsList,
    MatchResult,
    ClipboardInput,
    Notification,
    EditMatchResult,
    PickbanHub,
    HubManualCreateGameLobby,
    MatchStatisticTables,
  },
  setup() {
    const timeStore = useTimeStore();
    timeStore.fetchServerTime();
    return { timeStore };
  },
  data() {
    return {
      participantState: {},
      awaitTurn: true,
      isError: false,
      errorMessage: '',
      value: {
        title: 'Матч',
        type: 'match',
        list: 'match',
      },
      fetchedTeams: [],
      isLoading: {
        page: true,
        update: false,
      },
      windowWidth: window.innerWidth,
      success: false,
      errors: {},
      files: [],
      imgScreens: [],
      statusId: null,
      complaint: null,
      isMainError: false,
      isVisibleSaveButton: true,
      savedMatchResultMessage: '',
      homeTeamScore: 0,
      visitorTeamScore: 0,
      isTimeToShowSetScore: false,
      matchRound: {},
    };
  },
  computed: {
    ...mapGetters('profile', ['getHubUpdateKey', 'isLoggedIn']),
    ...mapGetters('tournaments', ['getTournament']),
    ...mapGetters('users', ['getUserMatchStatus', 'getUser']),
    ...mapGetters('teams', ['getTeam']),
    ...mapGetters('matches', [
      'getMatchPickBan',
      'getMatch',
      'getHubMatchState',
    ]),
    ...mapGetters('application', [
      'getGameMessageById',
      'getGameCode',
      'getGame',
    ]),

    matchResult() {
      return i18n.t('matches.dateStatus_completed');
    },

    resultNotification() {
      return i18n.t('matches.add_screenshots');
    },
    uploaderTitle() {
      return i18n.t('info.add_results_screenshot');
    },
    tournament() {
      return this.getTournament(this.hubID);
    },
    gameCode() {
      return this.getGameCode(this.tournament?.idGame);
    },
    uploaderPlaceholder() {
      return i18n.t('actions.select_file');
    },
    uploaderPlaceholderDesktop: [
      i18n.t('info.drop_file_here'),
      i18n.t('info.choose'),
    ],
    warningText() {
      return i18n.t('info.uploader_warning_text');
    },
    helpLink() {
      return {
        text: i18n.t('info.have_any_questions'),
        href: 'https://support.vkplay.ru/pvp/hubs',
      };
    },
    isMatch() {
      return this.participantState?.status === 'match';
    },
    matchId() {
      return this.participantState?.match?.id ?? 'new';
    },
    matchIdFromAddressBar() {
      return this.$route.params?.matchId;
    },
    hubID() {
      return this.$route.params.id;
    },
    gameId() {
      return this.tournament?.idGame;
    },
    hubName() {
      return this.tournament?.name;
    },
    hubState() {
      return this.participantState.status;
    },
    hubMatchState() {
      return this.getHubMatchState;
    },
    matchStates() {
      return this.hubMatchState?.states;
    },
    setScoreState() {
      return this.matchStates?.setScore?.state;
    },
    tsSinceSetScore() {
      return this.matchStates?.setScore?.details?.acceptSinceTs;
    },
    isSetScore() {
      return this.setScoreState === 'setScore';
    },
    canUserSetScore() {
      return this.matchStates?.setScore?.details?.canSet;
    },
    isScreenshotOnlySetScore() {
      return this.matchStates?.setScore?.details?.isScreenshotsOnly;
    },
    pickbanState() {
      return this.matchStates?.pickban?.state;
    },
    isPickban() {
      return this.pickbanState === 'pickban';
    },
    serverState() {
      return this.matchStates?.server?.state;
    },
    isWaitServerConfig() {
      return this.matchStates?.server?.details?.action === 'wait';
    },
    hasStartButton() {
      return (
        this.userStatus?.abilities?.canConnectToServer &&
        _.isObject(this.match?.server)
      );
    },
    ingameLobbyState() {
      return this.matchStates?.ingameLobby?.state;
    },
    ingameLobbyAction() {
      return this.matchStates?.ingameLobby?.details?.action;
    },
    showInGameLobby() {
      return this.ingameLobbyState === 'ingameLobby';
    },
    lobby() {
      return this.participantState.lobby;
    },
    lobbyTeams() {
      return this.lobby?.teams.map((team) => ({
        ...team,
        members: team.members.map((hash) => this.getUser(hash)),
      }));
    },
    linkToHub() {
      return `/tournament/${this.hubID}`;
    },
    pickTimeoutTimestamp() {
      return this.participantState?.lobby?.pickTimeoutTs;
    },
    isShowStatus() {
      return this.lobby?.participant.isCaptain;
    },
    isFinishedMatch() {
      return this.match?.tsEndTime ? true : false;
    },
    isWaitPick() {
      return this.lobby?.participant.action === 'wait';
    },
    isMobile() {
      const tabletWidth = 768;
      return this.windowWidth < tabletWidth;
    },
    isMatchInfoVisible() {
      return this.userStatus !== undefined;
    },
    canPickBan() {
      return this.userStatus?.abilities?.canPickBan;
    },
    matchStatusLabel() {
      if (this.match?.tsEndTime) {
        return {
          text: `${this.$t(
            'tournaments.status_completed',
          )} ${getDateString(this.match.tsEndTime, 'ru', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })}`,
          status: 'finished',
        };
      }
      return {
        text: this.$t('tournaments.matchExecuting'),
        status: 'match',
      };
    },
    teams() {
      if (this.lobby?.teams.length) {
        const [homeTeam, visitorTeam] = this.lobby.teams;
        const home = this.getTeamMembers(homeTeam);
        const visitor = this.getTeamMembers(visitorTeam);
        return {
          home,
          visitor,
        };
      }

      return {
        home: [],
        visitor: [],
      };
    },
    teamOne() {
      const team = this.getTeam(this.teamOneHash);
      return {
        ...team,
        captain:
          team?.players?.find((player) => player.isCaptain)?.hash ===
          this.userHash,
      };
    },

    teamTwo() {
      const team = this.getTeam(this.teamTwoHash);
      return {
        ...team,
        captain:
          team?.players?.find((player) => player.isCaptain)?.hash ===
          this.userHash,
      };
    },
    currentPickingCaptainHash() {
      return this.lobby?.currentlyPickingCaptainHash;
    },
    currentPickingUser() {
      return this.getUser(this.currentPickingCaptainHash);
    },
    participants() {
      const pickedParticipants = [
        ...this.teams.home,
        ...this.teams.visitor,
      ];
      return (
        this.lobby?.participants
          .filter((participant) => !participant.isCaptain)
          .filter(
            (participant) =>
              !pickedParticipants.some(
                (pickedParticipant) =>
                  participant.hash === pickedParticipant.hash,
              ),
          ) || []
      );
    },
    homeTeam() {
      return this.getMatchTeam(0);
    },
    visitorTeam() {
      return this.getMatchTeam(1);
    },
    homeMembers() {
      return this.homeTeam?.members ?? this.homeTeam?.players;
    },
    visitorMembers() {
      return this.visitorTeam?.members ?? this.visitorTeam?.players;
    },
    match() {
      return this.getMatch(this.matchIdFromAddressBar);
    },
    hasMatchStatistics() {
      const statistics = this.match?.statistics;
      return (
        statistics?.games !== undefined &&
        statistics?.games.length > 0
      );
    },
    manualLobbyInfo() {
      return this.match?.ingameLobby;
    },
    manualLobbyHeader() {
      switch (this.ingameLobbyAction) {
        case 'create':
          return this.$t('matches.need_create_lobby_action');
        case 'wait':
          return `${this.userNameWhichCreateLobby} ${this.$t(
            'matches.user_will_create_lobby_action',
          )}`;
        case 'play':
          return `${this.userNameWhichCreateLobby} ${this.$t(
            'matches.user_created_lobby_action',
          )}`;
        default:
          return '';
      }
    },
    manualLobbyInfoBox() {
      switch (this.ingameLobbyAction) {
        case 'create':
          return this.$t('matches.need_create_lobby_info');
        case 'wait':
          return this.$t('matches.need_wait_lobby_info');
        case 'play':
          return this.$t('matches.need_play_lobby_info');
        default:
          return '';
      }
    },
    userNameWhichCreateLobby() {
      const userHash = this.manualLobbyInfo?.responsiblePlayerHash;
      return this.getUser(userHash)?.name;
    },
    initialTimer() {
      return Math.floor(Date.now() / 1000) + 300;
    },
    tabs() {
      const tabs = [
        {
          title: 'Матч',
          type: 'match',
          list: 'match',
        },
      ];
      if (this.hasMatchStatistics) {
        tabs.push({
          title: 'Статистика',
          type: 'statistic',
          list: 'statistic',
        });
      }
      if (this.hubState === 'lobby') {
        return tabs;
      } else if (this.hubState === 'match' && this.isMobile) {
        tabs.push({
          title: 'Команды',
          type: 'teams',
          list: 'match',
        });
        return tabs;
      } else {
        return tabs;
      }
    },
    game() {
      const game = this.getGame(this.tournament.idGame);

      return {
        code: game.code,
        isHearthstone: game.code === 'hs',
        isWarface: game.code === 'wf',
        isCsGame: game.code === 'csg' || this.gameCode === 'cs2',
        linkType: game.linkType,
      };
    },
    userStatus() {
      return this.getUserMatchStatus(this.matchId);
    },
    canEditMatch() {
      return this.userStatus?.abilities?.canAddResult;
    },
    pickban() {
      return this.getMatchPickBan(this.matchId);
    },
    currentSide() {
      return this.pickban?.side;
    },
    captainPickingMap() {
      const currentTeam = this.fetchedTeams.find(
        (team) => team.side === this.currentSide,
      );
      return currentTeam?.players.find((team) => team.isCaptain);
    },
    isCsGame() {
      return this.game?.isCsGame;
    },
    isTechnicalDefeat() {
      return Boolean(this.match?.technicalDefeatHashList.length);
    },
    isCaptain() {
      return _.get(this.userStatus, 'roles.captain', false);
    },
    isJudge() {
      return _.get(this.userStatus, 'roles.judge', false);
    },
    isParticipant() {
      return _.get(this.userStatus, 'roles.participant', false);
    },
    isLoggedInNotParticipantUser() {
      return (
        !this.isParticipant &&
        this.isLoggedIn &&
        this.hubState !== 'lobby'
      );
    },
    isFinishedPickban() {
      const statusFinishedPickban = 3;
      return this.pickban?.status === statusFinishedPickban;
    },
    isShowServerIsLoading() {
      const statusFinishedPickban = 3;
      const isFinishedPickban =
        this.pickban?.status === statusFinishedPickban;
      return (
        !this.match?.hashWinner &&
        (this.isParticipant || this.isJudge) &&
        isFinishedPickban &&
        !this.userStatus.abilities.canConnectToServer &&
        this.isCsGame
      );
    },
  },
  watch: {
    async getHubUpdateKey() {
      if (this.isLoggedIn) {
        await this.fetchParticipantData();
      }
      if (this.matchIdFromAddressBar) {
        await this.fetchMatchUserAbilities(
          this.matchIdFromAddressBar,
        );
      }
      if (this.hubState === 'notParticipating') {
        await this.fetchMatchData(this.matchIdFromAddressBar);
      }
    },
    'participantState.match.id': async function (newId, oldId) {
      if (!oldId && this.matchIdFromAddressBar === 'new') {
        await this.fetchMatchData(newId);
        this.$router.replace(`/hub/${this.hubID}/match/${newId}`);
        await this.fetchMatchUserAbilities(newId);
        this.fetchMatchChannels(this.matchId);
      }
    },
    getServerTime: function (newVal) {
      if (newVal > this.tsSinceSetScore) {
        this.isTimeToShowSetScore = true;
      }
    },
    isFinishedMatch: function () {
      this.fetchMatchStatistic(this.matchIdFromAddressBar);
    },
    isFinishedPickban: function () {
      this.fetchMatchData(this.matchIdFromAddressBar);
    },
  },
  async mounted() {
    // TODO написать нормальный метод init
    this.savedMatchResultMessage = this.$t(
      'matches.default_message_save_result',
    );

    window.addEventListener('resize', this.handleResize);
    this.handleResize();

    if (this.isLoggedIn) {
      await this.fetchParticipantData();
    }
    await this.fetchTournamentPage(this.hubID);

    if (this.matchIdFromAddressBar !== 'new') {
      await this.fetchMatchData(this.matchIdFromAddressBar);

      window.addEventListener('visibilitychange', () => {
        this.handleFetchParticipantData();
        this.fetchMatchUserAbilities(this.$route.params.matchId);
      });
    }

    if (this.isFinishedMatch) {
      await this.fetchMatchStatistic(this.matchIdFromAddressBar);
    }

    this.fetchMatchUserAbilities(this.$route.params.matchId);
    this.fetchMatchChannels(this.$route.params.matchId);
  },

  beforeDestroy() {
    window.removeEventListener(
      'visibilitychange',
      this.handleFetchParticipantData,
    );
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    ...mapActions('matches', [
      'fetchMatch',
      'fetchMatchChannels',
      'fetchMatchUserAbilities',
      'matchCommonResult',
      'fetchMatchStatistic',
    ]),
    ...mapActions('tournaments', ['fetchTournamentPage']),

    goToHubPage() {
      this.$router.push(`/tournament/${this.hubID}`);
    },

    getMatchTeam(index) {
      const lobbyTeam = this.lobbyTeams ? this.lobbyTeams[index] : {};

      const matchTeam = this.fetchedTeams.find(
        (team) => team.side === index + 1,
      );

      if (matchTeam) {
        const captain = matchTeam?.players.find(
          (player) => player.isCaptain,
        );
        return { ...matchTeam, avatar: captain?.avatarUrl };
      }

      return { ...lobbyTeam, avatar: lobbyTeam?.avatarUrl };
    },

    async fetchParticipantData() {
      const { data } = await api.get(
        `hub/${this.hubID}/participant/state`,
      );
      this.participantState = data;
      this.$store.commit(
        'users/UPDATE_USERS',
        data?.lobby?.participants,
      );
    },

    async fetchMatchData(matchId) {
      const res = await this.fetchMatch(matchId);
      this.matchRound = res.round;
      this.fetchedTeams = res?.teams;
      const side1 = res?.teams.find((team) => team.side === 1).side;
      const side2 = res?.teams.find((team) => team.side === 2).side;
      this.homeTeamScore =
        side1 === 1
          ? res?.match?.pointsTeam1
          : res?.match?.pointsTeam2;
      this.visitorTeamScore =
        side2 === 2
          ? res?.match?.pointsTeam2
          : res?.match?.pointsTeam1;
      return res;
    },

    handleFetchParticipantData() {
      if (this.isLoggedIn && !document.hidden) {
        this.fetchParticipantData();
      }
    },

    getTeamMembers(team) {
      return team.members.map((hash) => {
        return this.lobby?.participants.find(
          (player) => hash === player.hash,
        );
      });
    },

    async handlePlayersPickClick(player) {
      this.isError = false;
      this.errorMessage = '';
      const playerHash = player.participant.hash;
      const payload = {
        hash: playerHash,
      };
      try {
        await api.post(
          `hub/${this.hubID}/lobby/${this.lobby.id}/pick`,
          payload,
        );
      } catch (err) {
        this.isError = true;
        this.errorMessage = err?.error?.errorMessage;
      }
    },
    async confirmCreateLobby() {
      const url = `match/${this.matchIdFromAddressBar}/lobby`;
      await api.post(url);
      await this.fetchMatchData(this.matchIdFromAddressBar);
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    // Ввод результатов матча
    addFiles(files) {
      this.files = files;
    },
    uploadFiles(file) {
      const formData = new FormData();
      formData.append('file', file.file);
      formData.append('type', 'matchResult');
      formData.append('id', this.matchId);

      file.loading = true;
      return api
        .post('/file/uploadandresize', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress(progressEvent) {
            const percent = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );
            file.loadedPercent = percent;
          },
        })
        .then((data) => {
          this.imgScreens.push(data.id);
          file.success = true;
          return true;
        })
        .catch(() => {
          file.error = this.$t('save.error');
          return false;
        })
        .finally(() => {
          file.loading = false;
        });
    },
    async saveResult(resultPayload) {
      this.success = false;
      this.errors = {};

      Promise.all(this.files?.map(this.uploadFiles) || []).then(
        () => {
          const teamHash = this.teamOne.captain
            ? this.teamOne.hash
            : this.teamTwo.hash;

          this.matchCommonResult({
            teamHash,
            id: this.matchId,
            specialStatus: this.statusId,
            complaint: this.complaint,
            pointsTeamOne: resultPayload.pointsTeamOne,
            pointsTeamTwo: resultPayload.pointsTeamTwo,
            imgScreens: this.imgScreens,
          })
            .then((data) => {
              this.success = true;
              if (data?.success) {
                this.isVisibleSaveButton = false;
                this.savedMatchResultMessage = this.$t(
                  'matches.match_result_saved_successfull',
                );
              }
            })
            .catch(({ error = [] }) => {
              this.errors = _.reduce(
                error,
                (acc, err, field) => ({
                  ...acc,
                  [field]: err.message,
                }),
                {},
              );
              if (error.code === 403) {
                this.isMainError = true;
              }
            });
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.hub-lobby-view-container {
  padding: 32px 16px;
  border-radius: 4px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.hub-lobby-view__content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}
.hub-lobby-view__hub-name {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  color: #f5f5f7;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  border-radius: 12px;
  background: #1d1d1e;
}
.hub-lobby-view__hub-link {
  color: #f5f5f7;
}
.hub-lobby-view__team-name--mobile {
  @include min-laptop() {
    display: none;
  }
}
.hub-lobby-view__team-name {
  color: #f5f5f7;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 12px;
  @include min-tablet() {
    font-size: 18px;
    line-height: 22px;
  }
}
.hub-lobby-view__tabs,
.hub-lobby-view__participants {
  margin-bottom: 16px;
}
.hub-lobby-view__wrapper {
  @include min-desktop() {
    display: grid;
    grid-template-columns: minmax(200px, 340px) 1fr minmax(
        200px,
        340px
      );
    gap: 24px;
    padding: 32px;
    border-radius: 12px;
    background: #1d1d1e;
  }
}
.hub-lobby-view__wrapper--tabs-view {
  display: block;
  width: 100%;
}

.hub-lobby-view__team {
  &--home-team {
    @include min-desktop() {
      order: -1;
    }
  }

  &:not(.hub-lobby-view__team--home-team) {
    @include min-desktop() {
      grid-area: 1 / 3;
    }
  }
}

.manual-lobby__info,
.manual-lobby__body {
  margin-bottom: 12px;
}

.manual-lobby__header {
  color: #f5f5f7;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.manual-lobby__notification {
  margin-bottom: 24px;
}
.set-score {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.set-score__timer {
  color: #65cb6d;
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}
.hub-lobby-view__teams--mobile {
  display: none;
  @include min-desktop() {
    display: block;
  }
}
.waiting-result__title,
.set-score__title {
  text-align: center;
  color: #f5f5f7;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 16px;
  @include min-tablet() {
    font-size: 18px;
    line-height: 22px;
  }
}
.waiting-result__notification {
  margin-bottom: 24px;
}

.waiting-result__link {
  display: block;
  text-align: center;
}

.hub-lobby-view__match-status {
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  padding: 4px 6px;
  font-weight: 400;
  border-radius: 6px;

  @include min-desktop() {
    font-size: 14px;
    line-height: 18px;
  }

  &--match {
    background: #65cb6d;
  }
  &--finished {
    background: #979797;
  }
}

.server-wait-message {
  margin-bottom: 24px;
}
.server-wait-message__title {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 12px;
}
</style>
