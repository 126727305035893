<template>
  <ul class="participants-list">
    <li
      class="participants-list__item participants-list__item--captain"
    >
      <MatchParticipant
        :participant="team.captain"
        :is-active="isActive"
        :is-big="isBig"
      />
    </li>
    <li
      v-for="participant in team.restTeam"
      :key="participant.id"
      class="participants-list__item participants-list__item--regular"
    >
      <MatchParticipant :participant="participant" :is-big="isBig" />
    </li>
  </ul>
</template>

<script>
import MatchParticipant from '@components/v2/match/MatchParticipant.vue';

export default {
  name: 'ParticipantsList',
  components: { MatchParticipant },
  props: {
    participants: {
      type: Array,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isBig: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    team() {
      const [captain, ...restTeam] = this.participants;

      return {
        captain,
        restTeam,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.participants-list {
  list-style-type: none;
}
.participants-list__item {
  &--captain {
    margin-bottom: 24px;
  }
  &--regular {
    margin-bottom: 8px;
  }
}
</style>
