<template>
  <div class="match-scores">
    <div
      class="match-scores__value match-scores__value--home"
      :class="getScoreModifyClass(homeData.result)"
    >
      {{ homeData.score }}
    </div>
    <div class="match-scores__separator">
      <Icon name="colon" inline />
    </div>
    <div
      class="match-scores__value match-scores__value--visitor"
      :class="getScoreModifyClass(visitorData.result)"
    >
      {{ visitorData.score }}
    </div>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'MatchScores',
  components: {
    Icon,
  },
  props: {
    home: {
      type: Number,
      default: 0,
    },
    visitor: {
      type: Number,
      default: 0,
    },
    techicalDefeat: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    homeData() {
      const result = this.getResult(this.home, this.visitor);
      return this.getSideData('home', result);
    },
    visitorData() {
      const result = this.getResult(this.visitor, this.home);
      return this.getSideData('visitor', result);
    },
  },
  methods: {
    getResult(teamA, teamB) {
      let result = '';
      if (teamA > teamB) {
        result = 'win';
      }
      if (teamA < teamB) {
        result = 'lose';
      }
      return result;
    },
    getScoreModifyClass(result) {
      return result ? `match-scores__value--${result}` : '';
    },
    getSideData(side, result) {
      if (this.techicalDefeat) {
        return {
          result,
          score:
            result === 'win'
              ? 1
              : this.$t('matches.tech_shortDefeat'),
        };
      }
      return {
        result,
        score: this[side],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.match-scores {
  display: flex;
  justify-content: center;
  text-align: center;
  gap: 2px;
  font-size: 18px;
  line-height: 1em;
  font-weight: 600;

  @include min-tablet() {
    gap: 16px;
    font-size: 56px;
    font-weight: 700;
  }
  @include min-desktop() {
    gap: 40px;
    font-size: 62px;
  }
}

.match-scores__value {
  width: 100%;

  &--home {
    text-align: right;
  }
  &--visitor {
    text-align: left;
  }

  &--win {
    color: #65cb6d;
  }
  &--lose {
    color: #c64040;
  }
}

.match-scores__separator {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  line-height: 1em;

  @include min-tablet() {
    font-size: 32px;
  }
}
</style>
