<template>
  <section class="pickban">
    <template v-if="isPickbanInProgress">
      <h2 class="pickban__title">
        Выбирает:
        <span>{{ userPickingName }}</span>
      </h2>
      <div v-if="userRole.captain" class="pickban__timer">
        <Timer
          v-if="deadline"
          :key="deadline"
          :initial="deadline"
          format="mm:ss"
          countdown
          @timeout="emitTimeout"
        />
      </div>
    </template>
    <div class="pickban__picking-list">
      <PickMaps
        :maps="maps"
        :is-ban="isBan"
        :disabled="disabled"
        :is-waiting-action="isWaitingAction"
        @click:ban="handleBanMapClick"
        @click:pick="handlePickMapClick"
      />
    </div>
    <div v-if="pickedMaps.length" class="pickban__picked-list">
      <h3 class="pickban__banlist-title">Список пиков</h3>
      <div class="pickban__banned-list">
        <PickMaps :maps="pickedMaps" disabled />
      </div>
    </div>
    <div v-if="bannedMaps.length" class="pickban__banned-list">
      <h3 class="pickban__banlist-title">Список банов</h3>
      <div class="pickban__banned-list">
        <PickMaps :maps="bannedMaps" disabled />
      </div>
      <a class="pickban__link" target="__blank" :href="helpLink.href">
        {{ helpLink.text }}
      </a>
    </div>
  </section>
</template>

<script>
import { isNull, isNumber } from 'lodash';
import Timer from '@components/v2/Timer.vue';
import PickMaps from '@components/v2/match/pickban/PickMaps.vue';

export default {
  name: 'Pickban',
  components: { Timer, PickMaps },
  props: {
    maps: {
      type: Array,
      default: () => [],
    },
    bannedMaps: {
      type: Array,
      default: () => [],
    },
    pickedMaps: {
      type: Array,
      default: () => [],
    },
    deadline: {
      required: true,
      validator: (value) => {
        return (
          isNull(value) || value === undefined || isNumber(value)
        );
      },
    },
    isPickbanInProgress: {
      type: Boolean,
      default: false,
    },
    isWaitingAction: {
      type: Boolean,
      default: false,
    },
    isBan: {
      type: Boolean,
      default: false,
    },
    userPicking: {
      type: Object,
      default: () => {},
    },
    userRole: {
      type: Object,
      default: () => {},
    },
    helpLink: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    userPickingName() {
      return this.userPicking?.tnNick;
    },
  },
  methods: {
    handleBanMapClick(map) {
      this.$emit('click:ban', map);
    },
    handlePickMapClick(map) {
      this.$emit('click:pick', map);
    },
    emitTimeout() {
      this.$emit('timeout');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.pickban__title,
.pickban__banlist-title {
  color: #f5f5f7;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 0.3px;
  text-align: center;
  margin-bottom: 8px;

  @include min-desktop() {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 12px;

    span {
      color: #979797;
    }
  }
}

.pickban__timer {
  text-align: center;
  color: #c64040;
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.3px;
  margin-bottom: 16px;

  @include min-tablet() {
    font-size: 32px;
    line-height: 1em;
    font-weight: 700;
    margin-bottom: 24px;
  }
}
.pickban__picking-list,
.pickban__banned-list {
  margin-bottom: 24px;
}
.pickban__link {
  display: block;
  color: #16a7ff;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  text-decoration: none;
  text-align: center;
}
</style>
