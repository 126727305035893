<template>
  <ul
    class="team-composition"
    :class="getTeamCompositonModifyClass()"
  >
    <li
      v-for="participant in teamParticipants"
      :key="participant.id"
      class="team-composition__participant"
      :class="getParticipantModifyClass()"
    >
      <img
        v-if="participant.avatarUrl"
        class="team-composition__avatar"
        :class="getAvatarModifyClass(participant.isCaptain)"
        :src="participant.avatarUrl"
        alt="participant avatar"
      />
      <div
        v-else
        class="
          team-composition__avatar team-composition__avatar--default
        "
        :class="getAvatarModifyClass(participant.isCaptain)"
      >
        <icon name="user" :inline="false"></icon>
      </div>
    </li>
  </ul>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
export default {
  name: 'TeamComposition',
  components: {
    Icon,
  },
  props: {
    reverse: {
      type: Boolean,
      default: false,
    },
    participants: {
      type: Array,
      required: true,
    },
  },
  computed: {
    teamParticipants() {
      if (this.participants) {
        const captain = this.participants.find((p) => p.isCaptain);
        const anotherPlayers = this.participants.filter(
          (p) => !p.isCaptain,
        );
        return [...anotherPlayers, captain];
      }
      return [];
    },
  },
  methods: {
    getTeamCompositonModifyClass() {
      return {
        'team-composition--reverse': this.reverse,
      };
    },
    getParticipantModifyClass() {
      return [
        `team-composition__participant--${
          this.reverse ? 'reverse' : 'default'
        }`,
      ];
    },
    getAvatarModifyClass(isCaptain) {
      return {
        'team-composition__avatar--captain': isCaptain,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.team-composition {
  display: inline-flex;

  &--reverse {
    flex-direction: row-reverse;
  }
}

.team-composition__participant {
  list-style-type: none;

  &--default {
    &:not(:first-child) {
      margin-left: -8px;
    }
  }
  &--reverse {
    &:not(:first-child) {
      margin-right: -8px;
      z-index: 1;
    }
  }
}
.team-composition__avatar {
  display: block;
  width: 28px;
  height: 28px;
  object-fit: cover;
  border-radius: 100%;
  border: 1px solid transparent;

  &--captain {
    border-color: #65cb6d;
  }
}
.team-composition__avatar--default {
  background: #454549;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
