<template>
  <div v-if="pickban" class="ban">
    <template v-if="needWait">
      <p class="pickban-title">
        {{ $t('matches.wf_pickban_startIn') }}:
      </p>
      <div class="time-left">
        <Timer
          v-if="pickban.pickBanStartTime"
          :key="updateKey"
          :initial="pickban.pickBanStartTime"
          format="mm:ss"
          countdown
          @timeout="onBeforeMapPickTimeout"
        />
      </div>
    </template>
    <template v-else>
      <Anticheat
        v-if="match.anticheat"
        class="anticheat"
        :anticheat="match.anticheat"
      />

      <div v-if="isShowServerIsLoading" class="ban__message">
        <p class="ban__message-text">
          Идет подготовка сервера к матчу
        </p>
        <loader />
      </div>

      <Pickban
        :maps="maps.unselected"
        :picked-maps="maps.picked"
        :banned-maps="maps.banned"
        :deadline="timeStore.getTimeWithDiff(pickban.expiredFull.ts)"
        :disabled="!pickban.current || needPickSide"
        :is-pickban-in-progress="isPickbanInProgress"
        :user-picking="userPicking"
        :user-role="role"
        :is-waiting-action="isWaitingAction"
        :is-ban="isBan"
        :help-link="helpLink"
        @click:ban="handleClickBanMap"
        @click:pick="handleClickPickMap"
        @timeout="onBeforeMapPickTimeout"
      />
    </template>
  </div>
</template>

<script>
import Timer from '@components/v2/Timer.vue';
import { uniqueId } from 'lodash';
import Pickban from '@components/v2/match/pickban/Pickban.vue';
import Anticheat from '@components/Match/Common/Anticheat.vue';
import { useTimeStore } from '@src/shared/store/useTimeStore';

// TODO копипаста с компонента пикбана Shooters.vue поставить задачу на рефактор
export default {
  name: 'PickbanHub',
  components: { Timer, Pickban, Anticheat },
  props: {
    matchId: {
      type: Number,
      required: true,
    },
    userPicking: {
      type: Object,
      default: () => {},
    },
    isShowServerIsLoading: {
      type: Boolean,
      required: false,
    },
    gameId: {
      type: [String, Number],
      required: true,
    },
    gameCode: {
      type: [String, Number],
      required: true,
    },
  },
  setup() {
    const timeStore = useTimeStore();
    timeStore.fetchServerTime();
    return { timeStore };
  },
  data() {
    return {
      loadingState: {
        mapPick: false,
        confirm: false,
      },
      mapCode: '',
      sideCode: '',
      catalogue: {},
      helpLink: {
        text: 'Возникли вопросы? Связаться с поддержкой',
        href: 'https://support.vkplay.ru/pvp/hubs',
      },
      updateKey: 0,
    };
  },
  computed: {
    ...mapGetters('matches', ['getMatchPickBan', 'getMatch']),
    ...mapGetters('users', ['getUserMatchStatus']),
    ...mapGetters('tournaments', ['getTournament']),

    match() {
      return this.getMatch(this.matchId);
    },

    isCsGame() {
      const csgoGameId = 24;
      const cs2Code = 'cs2';
      return this.gameId === csgoGameId || this.gameCode === cs2Code;
    },

    role() {
      return this.getUserMatchStatus(this.matchId)?.roles || {};
    },

    pickban() {
      return this.getMatchPickBan(this.matchId);
    },

    pickbanTitle() {
      if (!this.pickban.current) {
        return this.$t('matches.wf_pickban_status_waitOpponent');
      }
      if (this.pickban.status === 1) {
        if (this.needPickSide) {
          return this.$t('matches.wf_pickban_status_pickSide');
        }
        return this.$t('matches.wf_pickban_status_pickMap');
      }
      if (this.pickban.status === 2) {
        return this.$t('matches.wf_pickban_status_ban');
      }
      return '';
    },

    needWait() {
      return (
        this.pickban?.pickBanStartTime >= this.timeStore.serverTime
      );
    },

    maps() {
      const obj = {
        picked: [],
        banned: [],
        unselected: [],
      };

      const mapData = _.keyBy(this.catalogue?.maps, 'code');
      const filtered =
        this.pickban?.state?.reduce?.(
          (acc, { code, teamHash, sort, ...map }) => {
            const data = { ...mapData[code], teamHash, sort };
            if (map.picked) {
              const sides = Object.entries(map.sides).reduce(
                (acc, [key, value]) => ({
                  ...acc,
                  [value]: key,
                }),
                {},
              );
              data.sides = this.catalogue?.sides?.map((cls) => ({
                ...cls,
                teamHash: sides[cls.code],
                icon: cls?.code,
              }));
              acc.picked.push(data);
            } else if (map.banned) {
              acc.banned.push(data);
            } else {
              acc.unselected.push(data);
            }

            return acc;
          },
          obj,
        ) || obj;

      filtered.picked = filtered.picked.sort((a, b) =>
        a.sort > b.sort ? 1 : -1,
      );
      filtered.banned = filtered.banned.sort((a, b) =>
        a.sort > b.sort ? 1 : -1,
      );
      return filtered;
    },

    needPickSide() {
      //TODO разобраться зачем нужен needPickSide и переписать этот код?
      if (this.isCsGame) {
        return false;
      }
      return (
        this.maps?.picked?.filter(
          (map) =>
            map?.sides?.filter((side) => side.teamHash).length === 0,
        ).length > 0
      );
    },

    isPickbanInProgress() {
      return [1, 2].includes(this.pickban.status);
    },

    isMapsVisible() {
      return (
        this.pickban?.state?.length &&
        (this.pickban.status === 3 ||
          this.role.captain ||
          this.role.participant ||
          this.role.judge)
      );
    },
    pickbanAction() {
      return this.pickban.canBan ? 'ban' : 'pick';
    },
    isBan() {
      return this.pickbanAction === 'ban';
    },
    isWaitingAction() {
      return this.pickban.current === false;
    },
  },
  watch: {
    maps() {
      this.mapCode = '';
      this.sideCode = '';
    },
    pickban() {
      this.loadingState.mapPick = false;
    },
  },
  async mounted() {
    try {
      await this.fetchWfPickbanStatus(this.matchId);
      await this.fetchMapList(this.gameId).then((data) => {
        if (data) {
          this.catalogue = data;
        }
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  },
  methods: {
    ...mapActions('matches', [
      'fetchWfPickbanStatus',
      'fetchWfPickbanConfirm',
      'fetchMapList',
    ]),
    getRandomId() {
      return uniqueId();
    },
    onBeforeMapPickTimeout() {
      this.loadingState.mapPick = this.role?.captain;
    },
    setMapInfo(code, side = '') {
      if (this.pickban.current) {
        this.mapCode = code;
        this.sideCode = side;
      }
    },
    banMap(map) {
      this.loadingState.confirm = true;
      let action = this.pickban.canBan ? 'ban' : 'pick';
      if (this.needPickSide) {
        action = 'pick_class';
      }
      this.updateKey += 1;
      this.fetchWfPickbanConfirm({
        action,
        id: this.matchId,
        value: map.code,
        sideCode: this.sideCode || undefined,
      }).finally(() => {
        this.loadingState.confirm = false;
      });
    },
    handleClickBanMap(map) {
      this.banMap(map);
    },
    handleClickPickMap(map) {
      this.banMap(map);
    },
  },
};
</script>

<style lang="scss" scoped>
.anticheat {
  margin-bottom: 24px;
}
.ban__message {
  text-align: center;
}
.ban__message-text {
  margin-bottom: 12px;
}
</style>
