<template>
  <div class="uploader">
    <div
      class="uploader uploader--desktop"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <form
        class="uploader__form"
        enctype="multipart/form-data"
        novalidate
      >
        <h1
          class="uploader__title"
          :class="{ 'uploader__title--disabled': disabled }"
        >
          {{ title }}
        </h1>
        <div
          class="uploader__dropbox uploader__dropbox--desktop"
          :class="{
            'uploader__dropbox--disabled': disabled,
            'uploader__dropbox--hover': isDragging,
          }"
        >
          <div class="uploader__input-wrapper">
            <input
              ref="files"
              type="file"
              :multiple="multiple"
              :disabled="disabled"
              accept="image/*"
              class="uploader__input"
              @change="filesChange"
            />
          </div>
          <div
            class="uploader__content"
            :class="{ 'uploader__content--disabled': disabled }"
          >
            <Icon name="img" />
            <p>
              <span
                v-for="(phrase, idx) in placeholderDesktop"
                :key="idx"
                class="uploader__phrase"
                :class="{
                  'uploader__phrase--last-phrase':
                    placeholderDesktop.length - 1 === idx,
                }"
              >
                {{ phrase }}
              </span>
            </p>
          </div>
        </div>
      </form>
      <div
        v-if="!hasErrors"
        class="uploader__warning-placeholder"
        :class="{
          'uploader__warning-placeholder--disabled': disabled,
        }"
      >
        <Icon name="triangle-warning" />
        <p class="uploader__warning-text">{{ warningText }}</p>
      </div>
      <div v-if="hasErrors" class="uploader__errors">
        <Icon class="uploader__error-img" name="triangle-warning" />
        <p
          v-for="(error, idx) in errors"
          :key="idx"
          class="uploader__error-text"
        >
          {{ error }}
        </p>
      </div>
    </div>
    <div class="uploader uploader--mobile">
      <form
        enctype="multipart/form-data"
        novalidate
        class="uploader__form"
      >
        <h1
          class="uploader__title"
          :class="{ 'uploader__title--disabled': disabled }"
        >
          {{ title }}
        </h1>
        <div
          class="uploader__dropbox"
          :class="{ 'uploader__dropbox--disabled': disabled }"
        >
          <div class="uploader__input-wrapper">
            <input
              type="file"
              multiple
              :disabled="disabled"
              accept="image/*"
              class="uploader__input"
              @change="filesChange"
            />
          </div>
          <p
            class="uploader__placeholder"
            :class="{ 'uploader__placeholder--disabled': disabled }"
          >
            <Icon name="plus"></Icon>
            {{ placeholder }}
          </p>
        </div>
      </form>
      <div v-if="hasErrors" class="uploader__errors">
        <Icon class="uploader__error-img" name="triangle-warning" />
        <p
          v-for="(error, idx) in errors"
          :key="idx"
          class="uploader__error-text"
        >
          {{ error }}
        </p>
      </div>
    </div>

    <div v-if="files.length" class="uploader__files">
      <div
        v-for="(file, idx) in files"
        :key="idx"
        class="uploader__file"
      >
        <div class="uploader__file-box">
          <img :src="file.src" class="uploader__img" />
          <div class="uploader__file-name">
            {{ file.file.name }}
          </div>
          <button
            class="uploader__close-btn"
            @click="deleteFile(idx)"
          >
            <Icon name="x"></Icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
import { i18n } from '@src/localization/config';

export default {
  name: 'FileUploader',
  components: { Icon },
  props: {
    title: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    placeholderDesktop: {
      type: Array,
      default: () => [],
    },
    warningText: {
      type: String,
      default: '',
    },
    allowedFormats: {
      type: Array,
      default: () => ['jpg', 'jpeg', 'png'],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    maxFileSize: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      files: [],
      errors: [],
      isDragging: false,
    };
  },
  computed: {
    hasErrors() {
      return this.errors.length > 0;
    },
  },
  methods: {
    filesChange(event) {
      const files = [...event.target.files];
      this.mapFiles(files);
    },

    dragover(event) {
      event.preventDefault();
      this.isDragging = true;
    },

    dragleave() {
      this.isDragging = false;
    },

    drop(event) {
      event.preventDefault();
      this.$refs.files.files = event.dataTransfer.files;
      this.filesChange(event);
      this.isDragging = false;
    },

    deleteFile(index) {
      this.$delete(this.files, index);
      this.emitData();
    },

    emitData() {
      this.$emit('input', this.files);
    },

    validate(file) {
      const errors = [];
      const formatFile = file.name.split('.').at(-1);
      if (
        this.maxFileSize &&
        file.size / 1024 ** 2 > this.maxFileSize
      ) {
        errors.push(
          i18n.t('uploader.maxFileSize', {
            count: this.maxFileSize,
          }),
        );
        this.errors = [...new Set(errors)];
        return;
      }
      if (!this.allowedFormats.includes(formatFile)) {
        errors.push(i18n.t('uploader.invalidExt'));
        this.errors = [...new Set(errors)];
        return;
      } else {
        this.errors = [];
      }
    },

    mapFiles(files) {
      files.forEach(async (file) => {
        const src = await (async () => {
          if (/image/.test(file.type)) {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            const event = await new Promise((resolve) => {
              reader.onload = resolve;
            });

            return event.target.result;
          }
          return '';
        })();

        this.validate(file);

        if (this.errors.length === 0) {
          this.files.push({
            file,
            src,
            loading: false,
            success: false,
            loadedPercent: 0,
            fileSize: (file.size / 1024 / 1024).toFixed(2),
          });
        }

        this.emitData();
      });
    },
  },
};
</script>

<style lang="scss">
@import '~@assets/scss/common/brakepoints';

.uploader {
  margin-bottom: 12px;
}

.uploader--desktop {
  display: none;

  @include min-desktop() {
    display: block;
  }
}

.uploader--mobile {
  @include min-desktop() {
    display: none;
  }
}

.uploader__dropbox {
  outline: 2px dashed #f5f5f7;
  border-radius: 6px;
  background: transparent;
  height: 54px;
  position: relative;
  cursor: pointer;
}

.uploader__dropbox--desktop {
  height: 150px;
}

.uploader__dropbox--disabled {
  outline: 2px dashed #979797;
  pointer-events: none;
}

.uploader__title {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 12px;
  color: #f5f5f7;
}

.uploader__title--disabled {
  color: #979797;
}

.uploader__input-wrapper {
  width: 100%;
  height: 100%;
}

.uploader__input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
  z-index: 100;
}

.uploader__input:disabled {
  cursor: no-drop;
}

.uploader__dropbox:hover,
.uploader__dropbox--hover {
  background: rgba(#f5f5f7, $alpha: 0.12);
}

.uploader__placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #f5f5f7;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
}

.uploader__placeholder:before {
  content: '';
  border: 1px solid #f5f5f7;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translate(-50%, -50%);
}

.uploader__placeholder--disabled {
  color: #979797;
  cursor: no-drop;
}

.uploader__content {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #f5f5f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  text-align: center;
  width: 155px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.uploader__content--disabled {
  color: #979797;
}

.uploader__phrase {
  display: inline;
}

.uploader__phrase--last-phrase {
  display: inline;
  color: #16a7ff;
}
.uploader__form {
  margin-bottom: 6px;
}

.uploader__warning-placeholder {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #f5f5f7;
  width: 260px;
  display: flex;
  justify-content: flex-start;
  gap: 6px;
}

.uploader__warning-placeholder--disabled {
  color: #979797;
}

.uploader__warning-text {
  width: 230px;
}

.uploader__file-box {
  border-radius: 12px;
  background: #2d2d30;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  margin-bottom: 8px;
  position: relative;
}

.uploader__file-box:hover {
  box-shadow: 0px 0px 45px 0px rgba(255, 255, 255, 0.25);
}

.uploader__img {
  width: 96px;
  height: 54px;
  object-fit: cover;
  border-radius: 8px;
}

.uploader__file-name {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #f5f5f7;

  @include min-desktop() {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }
}

.uploader__close-btn {
  width: 24px;
  height: 24px;
  border: none;
  color: #f5f5f7;
  background: transparent;
  position: absolute;
  top: 12px;
  right: 12px;
}

.uploader__errors {
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #c64040;
}

.uploader__error-text {
  padding-left: 30px;
}

.uploader__error-img {
  position: absolute;
}
</style>
