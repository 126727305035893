<template>
  <span class="pick-rating">
    <Icon name="chart-arrow" />
    {{ value }}
  </span>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'PickRating',
  components: {
    Icon,
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.pick-rating {
  display: flex;
  align-items: center;
  gap: 6px;

  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  @include min-tablet() {
    font-size: 22px;
    line-height: 26px;
  }
}
</style>
