<template>
  <header class="match-header">
    <div class="match-header__content">
      <div class="match-header__team">
        <MatchTeam :name="homeTeam.name" :image="homeTeam.avatar" />
      </div>
      <div class="match-header__total">
        <MatchScores
          :home="homeTeamScore"
          :visitor="visitorTeamScore"
          :techical-defeat="techicalDefeat"
        />
      </div>
      <div class="match-header__team">
        <MatchTeam
          :name="visitorTeam.name"
          :image="visitorTeam.avatar"
        />
      </div>
    </div>
    <div class="match-header__team-composition">
      <TeamComposition :participants="homeMembers" />
      <TeamComposition :participants="visitorMembers" reverse />
    </div>
  </header>
</template>

<script>
import MatchTeam from '@components/v2/match/MatchTeam.vue';
import MatchScores from '@components/v2/match/MatchScores.vue';
import TeamComposition from '@components/v2/match/TeamComposition.vue';

export default {
  name: 'MatchHeader',
  components: {
    MatchTeam,
    MatchScores,
    TeamComposition,
  },
  props: {
    homeTeam: {
      type: Object,
      required: true,
    },
    visitorTeam: {
      type: Object,
      required: true,
    },
    homeTeamScore: {
      type: [String, Number],
      default: 0,
    },
    visitorTeamScore: {
      type: [String, Number],
      default: 0,
    },
    techicalDefeat: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    homeMembers() {
      return this.homeTeam?.members ?? this.homeTeam?.players;
    },
    visitorMembers() {
      return this.visitorTeam?.members ?? this.visitorTeam?.players;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.match-header {
  color: #f5f5f7;
  background: #1d1d1e;
  border-radius: 6px;

  @include min-tablet() {
    border-radius: 12px;
  }
}

.match-header__content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 8px;

  @include min-tablet() {
    gap: 32px;
    padding: 24px;
  }
  @include min-desktop() {
    gap: 48px;
    max-width: 920px;
    width: 100%;
    margin: 0 auto;
  }
}

.match-header__team {
  flex-shrink: 0;
  width: calc(50% - 32px - 2px);

  @include min-tablet() {
    width: calc(50% - 100px - 16px);
  }
}

.match-header__total {
  width: 80px;

  @include min-tablet() {
    width: 200px;
    flex-shrink: 0;
  }
}

.match-header__team-composition {
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #2d2d30;
  padding: 12px;

  @include min-tablet() {
    display: none;
  }
}
</style>
