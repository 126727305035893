<template>
  <div class="match-content">
    <h2 class="match-content__title">{{ title }}</h2>

    <div class="match-content__slot">
      <slot />
    </div>

    <div v-if="buttonText" class="match-content__button">
      <Button
        type="primary"
        size="regular"
        :text="buttonText"
        long
        @click="$emit('click')"
      />
    </div>
    <a class="match-content__link" :href="helpLink.href">
      {{ helpLink.text }}
    </a>
  </div>
</template>

<script>
import Button from '@components/v2/ui/Button.vue';

export default {
  name: 'MatchСontent',
  components: {
    Button,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      helpLink: {
        text: 'Возникли вопросы? Связаться с поддержкой',
        href: 'https://support.vkplay.ru/pvp/hubs',
      },
    };
  },
};
</script>

<style>
.match-content {
  color: #f5f5f7;
  padding-bottom: 12px;
}

.match-content__title {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 24px;
}

.match-content__slot {
  margin-bottom: 24px;
}

.match-content__button {
  margin-bottom: 16px;
}

.match-content__link {
  display: block;
  color: #16a7ff;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  text-decoration: none;
  text-align: center;
}
</style>
