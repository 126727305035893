<template>
  <ul class="pick-maps-list">
    <li
      v-for="map in maps"
      :key="map.code"
      class="pick-maps-list__item"
    >
      <PickCard :disabled="disabled">
        <div class="pick-maps__wrapper">
          <img
            v-if="map.image"
            class="pick-maps-list__card-img"
            :src="map.image"
            alt="map picture"
          />
          <p class="pick-maps__name">{{ map.name }}</p>
          <div
            v-if="map.userMadeChoise"
            class="pick-maps__user-made-choise"
          >
            <img
              class="pick-maps__user-avatar"
              :src="map.userMadeChoise.src"
              alt="user avatar"
            />
          </div>
          <div v-else class="pick-maps__buttons">
            <template v-if="!isWaitingAction">
              <button
                v-if="isBan"
                :disabled="disabled"
                class="pick-maps-list__btn"
                :class="buttonCssClasses"
                @click="handleBanButtonClick(map)"
              >
                <Icon name="crossed-out-circle" :size="14" />
                <span class="pick-maps-list__ban-btn">Бан</span>
              </button>
              <button
                v-else
                :disabled="disabled"
                class="pick-maps-list__btn pick-maps-list__btn--pick"
                :class="buttonCssClasses"
                @click="handlePickButtonClick(map)"
              >
                <Icon name="check" :size="14" />
                <span class="pick-maps-list__ban-btn">Пик</span>
              </button>
            </template>
          </div>
        </div>
      </PickCard>
    </li>
  </ul>
</template>

<script>
import PickCard from '@components/v2/match/pick/PickCard.vue';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'PickMaps',
  components: {
    Icon,
    PickCard,
  },
  props: {
    maps: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isBan: {
      type: Boolean,
      default: false,
    },
    isWaitingAction: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonCssClasses() {
      return [this.disabled ? 'button--disabled' : ''];
    },
  },
  methods: {
    handleBanButtonClick(map) {
      this.$emit('click:ban', map);
    },
    handlePickButtonClick(map) {
      this.$emit('click:pick', map);
    },
  },
};
</script>

<style lang="scss" scoped>
.pick-maps-list {
  list-style: none;
}
.pick-maps-list__item {
  margin-bottom: 8px;
}
.pick-maps__wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.pick-maps-list__card-img {
  width: 96px;
  height: 54px;
  border-radius: 12px;
  object-fit: cover;
}
.pick-maps__name {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #f5f5f7;
  word-break: break-all;
}
.pick-maps__buttons,
.pick-maps__user-made-choise {
  margin-left: auto;
}
.pick-maps-list__btn {
  display: flex;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  border: 1px solid #c64040;
  background: #454549;
  color: #f5f5f7;
  cursor: pointer;
}
.pick-maps-list__ban-btn {
  border: none;
  background: initial;
  color: #f5f5f7;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
}
.pick-maps-list__btn--pick {
  border: 1px solid #65cb6d;
}
.pick-maps__user-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
}
.button--disabled {
  pointer-events: none;
  background: var(--button-bg-disabled);
  border: 1px solid grey;
}
</style>
