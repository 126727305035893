<template>
  <ul class="pick-participants-list">
    <h3 v-if="title" class="pick-participants-list__title">
      {{ title }}
    </h3>
    <li
      v-for="participant in participants"
      :key="participant.id"
      class="pick-participants-list__item"
    >
      <PickCard
        :disabled="disabled"
        @click="handlePickCardClick(participant)"
      >
        <div class="pick-participants-list__user">
          <div class="pick-participants-list__user-data">
            <PickUser
              :user="participant"
              :user-name="participant.name.pvp"
              :image="participant.image"
              :pvp-href="participant.pvpHref"
              :steam-href="participant.steamHref"
              :win="participant.win"
              :lose="participant.lose"
              :draw="participant.draw"
            />
          </div>
          <div class="pick-participants-list__user-rating">
            <PickRating :value="participant.stats.rating.value" />
          </div>
        </div>
      </PickCard>
    </li>
  </ul>
</template>

<script>
import PickCard from '@components/v2/match/pick/PickCard.vue';
import PickUser from '@components/v2/match/pick/PickUser.vue';
import PickRating from '@components/v2/match/pick/PickRating.vue';

export default {
  name: 'PickParticipants',
  components: {
    PickCard,
    PickUser,
    PickRating,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    participants: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handlePickCardClick(participant) {
      this.$emit('click', { participant });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.pick-participants-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.pick-participants-list__title {
  color: #f5f5f7;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
}

.pick-participants-list__item {
  list-style-type: none;
}

.pick-participants-list__user {
  display: flex;
  align-items: center;
}

.pick-participants-list__user-data {
  width: calc(100% - 80px);
  @include min-tablet() {
    width: calc(100% - 150px);
  }
}

.pick-participants-list__user-rating {
  display: flex;
  justify-content: flex-end;
  width: 80px;
  @include min-tablet() {
    width: 150px;
  }
}
</style>
