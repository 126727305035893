<template>
  <div class="steam-avatar" :class="steamAvatarCssClass">
    <img
      v-if="src"
      class="steam-avatar__image"
      :src="src"
      alt="user avatar"
    />
    <div
      v-else
      class="player__avatar player__avatar--default"
      :class="steamAvatarCssClass"
    >
      <Icon name="user" :inline="false" />
    </div>

    <a class="steam-avatar__link" :href="href" target="_blank">
      <Icon class="steam-avatar__icon" name="steam" />
    </a>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'SteamAvatar',
  components: {
    Icon,
  },
  props: {
    src: {
      type: String,
      default: '',
    },
    href: {
      type: String,
      default: '',
    },
    isBig: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    steamAvatarCssClass() {
      return {
        'steam-avatar--big': this.isBig,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.steam-avatar {
  width: 36px;
  height: 36px;
  position: relative;
}

.steam-avatar--big {
  @include min-tablet() {
    width: 64px;
    height: 64px;
  }
}

.steam-avatar__image {
  display: block;
  width: inherit;
  height: inherit;
  border-radius: 100%;
  object-fit: cover;
}

.steam-avatar__link {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #979797;

  @include min-tablet() {
    right: -5px;
  }
}

.steam-avatar__icon {
  display: block;
  width: 16px;
  height: 16px;

  .steam-avatar--big & {
    @include min-tablet() {
      width: 24px;
      height: 24px;
    }
  }
}
.player__avatar--default {
  background: #454549;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  border-radius: 50%;
}
</style>
