<template>
  <MatchContent
    :title="status"
    :button-text="buttonText"
    @click="$emit('click')"
  >
    <article v-if="map.title" class="match-result">
      <h3 class="match-result__title">{{ title }}</h3>
      <div class="match-result__map">
        <img
          class="match-result__map-image"
          :src="map.imgSrc"
          alt="map image preview"
        />
        <h3 class="match-result__map-title">{{ map.title }}</h3>
      </div>
    </article>
  </MatchContent>
</template>

<script>
import MatchContent from '@components/v2/match/MatchContent.vue';

export default {
  name: 'MatchResult',
  components: {
    MatchContent,
  },
  props: {
    status: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    map: {
      type: Object,
      default: () => {
        return {
          imgSrc: '',
          title: '',
        };
      },
    },
    buttonText: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.match-result__title {
  margin-bottom: 12px;
}

.match-result__map {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px;
  background: #2d2d30;
  border-radius: 12px;
}

.match-result__map-image {
  width: 96px;
  height: 54px;
  border-radius: 8px;
  object-fit: cover;
}

.match-result__map-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.3px;
}
</style>
