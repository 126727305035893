var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hub-lobby-view"},[_c('div',{staticClass:"hub-lobby-view-container"},[_c('div',{staticClass:"hub-lobby-view__content"},[_c('div',{staticClass:"hub-lobby-view__label"},[_c('span',{staticClass:"hub-lobby-view__match-status",class:`hub-lobby-view__match-status--${_vm.matchStatusLabel.status}`},[_vm._v(" "+_vm._s(_vm.matchStatusLabel.text)+" ")])]),_c('div',{staticClass:"hub-lobby-view__hub-name"},[_c('a',{staticClass:"hub-lobby-view__hub-link",attrs:{"href":_vm.linkToHub}},[_vm._v(" "+_vm._s(_vm.hubName)+" ")])]),(_vm.homeTeam)?_c('MatchHeader',{attrs:{"home-team":_vm.homeTeam,"visitor-team":_vm.visitorTeam,"home-team-score":_vm.homeTeamScore,"visitor-team-score":_vm.visitorTeamScore,"techical-defeat":_vm.isTechnicalDefeat}}):_vm._e(),_c('div',{staticClass:"hub-lobby-view__tabs"},[_c('TabListButtons',{attrs:{"tabs":_vm.tabs,"tab-key":"title"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1),_c('div',{staticClass:"hub-lobby-view__wrapper",class:{
          'hub-lobby-view__wrapper--tabs-view':
            _vm.value.type === 'statistic',
        }},[(_vm.value.type === 'match')?[(_vm.hubState === 'lobby')?[_c('div',{staticClass:"hub-lobby-view__participants"},[_c('PickParticipants',{attrs:{"participants":_vm.participants,"team-home":_vm.teams.home,"team-visitor":_vm.teams.visitor,"await-turn":_vm.isWaitPick,"user-picking":_vm.currentPickingUser,"is-big":true,"deadline":_vm.timeStore.getTimeWithDiff(_vm.pickTimeoutTimestamp)},on:{"click":_vm.handlePlayersPickClick}})],1)]:_vm._e(),(_vm.showInGameLobby)?[_c('HubManualCreateGameLobby',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',{staticClass:"manual-lobby__header"},[_vm._v(" "+_vm._s(_vm.manualLobbyHeader)+" ")])]},proxy:true},{key:"body",fn:function(){return [_c('Notification',{staticClass:"manual-lobby__notification",attrs:{"type":"info","horizontal":"","title":_vm.manualLobbyInfoBox}},[(_vm.ingameLobbyAction === 'create')?_c('a',{attrs:{"href":`/tournament/${_vm.hubID}?tab=rules`,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('matches.link_to_game_rules'))+" ")]):_vm._e()]),_c('ClipboardInput',{staticClass:"manual-lobby__body",attrs:{"label":_vm.$t('matches.lobby_name'),"copy-label":"Скопировать","value":_vm.manualLobbyInfo.name}})]},proxy:true},(_vm.ingameLobbyAction === 'create')?{key:"footer",fn:function(){return [_c('Button',{attrs:{"type":"primary","size":"big","long":"","text":_vm.$t('matches.confirm_create_lobby')},on:{"click":_vm.confirmCreateLobby}})]},proxy:true}:null],null,true)})]:_vm._e(),(_vm.hubState === 'match')?[(_vm.isSetScore && !_vm.isTimeToShowSetScore)?[_c('div',{staticClass:"set-score"},[_c('h3',{staticClass:"set-score__title"},[_vm._v(" "+_vm._s(_vm.$t('matches.wait_for_set_score'))+" ")]),_c('div',{staticClass:"set-score__timer"},[_c('Timer',{attrs:{"initial":_vm.timeStore.getTimeWithDiff(_vm.tsSinceSetScore),"format":"mm:ss","countdown":""}})],1)])]:_vm._e(),(
                _vm.isSetScore &&
                _vm.isTimeToShowSetScore &&
                _vm.canUserSetScore === true
              )?_c('EditMatchResult',{attrs:{"home-team-name":_vm.homeTeam?.name,"visitor-team-name":_vm.visitorTeam?.name,"warning-text":_vm.warningText,"uploader-title":_vm.uploaderTitle,"uploader-placeholder":_vm.uploaderPlaceholder,"uploader-placeholder-desktop":_vm.uploaderPlaceholderDesktop,"is-loading":_vm.isLoading.update,"is-visible-save-button":_vm.isVisibleSaveButton,"saved-match-result-message":_vm.savedMatchResultMessage,"is-screenshot-only-set-score":_vm.isScreenshotOnlySetScore},on:{"addFiles":_vm.addFiles,"saveResult":_vm.saveResult}}):_vm._e(),(
                _vm.isSetScore &&
                _vm.isTimeToShowSetScore &&
                _vm.canUserSetScore === false
              )?_c('div',{staticClass:"waiting-result"},[_c('h3',{staticClass:"waiting-result__title"},[_vm._v(" "+_vm._s(_vm.$t('matches.waiting_result'))+" ")]),_c('Notification',{staticClass:"waiting-result__notification",attrs:{"type":"info","horizontal":"","title":_vm.$t('matches.match_result'),"subtitle":_vm.$t('matches.match_waiting_result')}}),_c('a',{staticClass:"waiting-result__link",attrs:{"target":"__blank","href":_vm.helpLink.href}},[_vm._v(" "+_vm._s(_vm.helpLink.text)+" ")])],1):_vm._e(),(_vm.isPickban)?_c('PickbanHub',{attrs:{"match-id":_vm.matchId,"game-id":_vm.gameId,"game-code":_vm.gameCode,"user-picking":_vm.captainPickingMap,"is-show-server-is-loading":_vm.isShowServerIsLoading}}):_vm._e()]:_vm._e(),(_vm.serverState === 'server')?[(_vm.isWaitServerConfig)?_c('div',{staticClass:"server-wait-message"},[_c('h2',{staticClass:"server-wait-message__title"},[_vm._v(" "+_vm._s(_vm.$t('main.waitServerForMatch'))+" ")]),_c('loader',{staticClass:"server-wait-message__loader",attrs:{"size":32}})],1):_vm._e(),(_vm.hasStartButton)?_c('StartGame',{attrs:{"server":_vm.match.server,"is-cs-game":_vm.isCsGame,"game-code":_vm.game.code}}):_vm._e()]:_vm._e(),(
              (!_vm.isLoggedIn && !_vm.isFinishedMatch) ||
              (_vm.isLoggedInNotParticipantUser && !_vm.isFinishedMatch)
            )?[_c('Notification',{staticClass:"waiting-result__notification",attrs:{"type":"info","horizontal":"","title":_vm.$t('matches.match_is_going')}})]:_vm._e(),(_vm.isFinishedMatch)?[_c('MatchResult',{attrs:{"status":_vm.matchResult,"button-text":'Перейти к хабу'},on:{"click":_vm.goToHubPage}})]:_vm._e(),_c('div',{staticClass:"hub-lobby-view__team hub-lobby-view__team--home-team"},[(_vm.homeMembers)?_c('h3',{staticClass:"hub-lobby-view__team-name hub-lobby-view__team-name--mobile"},[_vm._v(" "+_vm._s(_vm.homeTeam.name)+" ")]):_vm._e(),(_vm.homeMembers)?_c('ParticipantsList',{attrs:{"participants":_vm.homeMembers}}):_vm._e()],1),_c('div',{staticClass:"hub-lobby-view__team"},[(_vm.visitorMembers)?_c('h3',{staticClass:"hub-lobby-view__team-name hub-lobby-view__team-name--mobile"},[_vm._v(" "+_vm._s(_vm.visitorTeam.name)+" ")]):_vm._e(),(_vm.visitorMembers)?_c('ParticipantsList',{attrs:{"participants":_vm.visitorMembers}}):_vm._e()],1)]:_vm._e(),(_vm.value.type === 'teams')?[_c('div',{staticClass:"hub-lobby-view__team hub-lobby-view__team--home-team"},[(_vm.homeTeam)?_c('h3',{staticClass:"hub-lobby-view__team-name hub-lobby-view__team-name--mobile"},[_vm._v(" "+_vm._s(_vm.homeTeam.name)+" ")]):_vm._e(),_c('ParticipantsList',{attrs:{"participants":_vm.homeMembers}})],1),_c('div',{staticClass:"hub-lobby-view__team"},[(_vm.homeTeam)?_c('h3',{staticClass:"hub-lobby-view__team-name hub-lobby-view__team-name--mobile"},[_vm._v(" "+_vm._s(_vm.visitorTeam.name)+" ")]):_vm._e(),_c('ParticipantsList',{attrs:{"participants":_vm.visitorMembers}})],1)]:_vm._e(),(_vm.value.type === 'statistic')?[_c('MatchStatisticTables',{attrs:{"id":_vm.matchIdFromAddressBar}})]:_vm._e()],2)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }