<template>
  <div class="match-participant" :class="matchParticipantClass">
    <div class="match-participant__avatar">
      <SteamAvatar
        :src="participant.avatarUrl"
        :href="participant.gameProfileLink"
        :is-big="isBig"
      />
    </div>
    <div class="match-participant__main">
      <router-link
        :to="{ name: 'profile', params: { hash: participant.hash } }"
        class="pick-user__username"
        :href="participant.pvpHref"
        target="_blank"
      >
        {{ participantName }}
      </router-link>
      <div v-if="participant.label" class="match-participant__label">
        {{ participant.label }}
      </div>
    </div>
  </div>
</template>

<script>
import SteamAvatar from '@components/v2/match/pick/SteamAvatar.vue';

export default {
  name: 'MatchParticipant',
  components: { SteamAvatar },
  props: {
    participant: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isBig: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    matchParticipantClass() {
      return {
        'match-participant--active': this.isActive,
      };
    },
    participantName() {
      return this.participant?.tnNick ?? this.participant?.name?.pvp;
    },
  },
};
</script>

<style lang="scss" scoped>
.match-participant {
  display: flex;
  align-items: center;
  gap: 12px;
  min-height: 52px;
  padding: 6px;
  border-radius: 12px;
  background: #2d2d30;
  border: 2px solid #2d2d30;
  color: #f5f5f7;
  font-size: 16px;
  font-weight: 600;
  line-height: 125%;
  letter-spacing: 0.3px;

  &--active {
    border-color: #e2e3e7;
    box-shadow: 0px 0px 25px 0px rgba(255, 255, 255, 0.2);
  }
}

.pick-user__username {
  color: #f5f5f7;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.3px;
  text-decoration: none;
  word-break: break-all;
}
.match-participant__label {
  color: #979797;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
}
</style>
