<template>
  <div class="match-team">
    <h3 class="match-team__name" :title="name">
      {{ name }}
    </h3>
    <img
      v-if="image"
      class="match-team__image"
      :src="image"
      alt="team avatar"
    />
    <div v-else class="match-team__default-image">
      <icon
        class="match-team__icon"
        name="user-centered"
        :inline="false"
      />
    </div>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
export default {
  name: 'MatchTeam',
  components: {
    Icon,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.match-team {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.match-team__name {
  width: 100%;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 6px;

  @include min-tablet() {
    font-size: 22px;
    line-height: 26px;
    font-weight: 600;
    margin-bottom: 12px;
  }
}

.match-team__image {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  object-fit: cover;

  @include min-tablet() {
    width: 96px;
    height: 96px;
  }
}
.match-team__default-image {
  width: 32px;
  height: 32px;
  background: #454549;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  @include min-tablet() {
    width: 96px;
    height: 96px;
  }
}
.match-team__icon {
  width: 24px;
  height: 24px;
  @include min-tablet() {
    width: 48px;
    height: 48px;
  }
}
</style>
