<template>
  <section class="pick-participants">
    <h2 class="pick-participants__title">
      Выбирает:
      <span>{{ userPickingName }}</span>
    </h2>
    <div class="pick-participants__timer">
      <Timer :initial="deadline" short countdown />
    </div>
    <div class="pick-participants__list">
      <ParticipantsList
        :participants="participants"
        :disabled="awaitTurn"
        :is-big="isBig"
        @click="handleParticipantsListClick"
      />
    </div>
  </section>
</template>

<script>
import Timer from '@components/v2/Timer.vue';
import ParticipantsList from '@components/v2/match/pick/ParticipantsList.vue';

export default {
  name: 'PickParticipants',
  components: {
    Timer,
    ParticipantsList,
  },
  props: {
    participants: {
      type: Array,
      required: true,
    },
    teamHome: {
      type: Array,
      required: true,
    },
    teamVisitor: {
      type: Array,
      required: true,
    },
    deadline: {
      type: Number,
      required: true,
    },
    userPicking: {
      type: Object,
      default: () => {},
    },
    awaitTurn: {
      type: Boolean,
      default: true,
    },
    isBig: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    userPickingName() {
      return this.userPicking?.name?.pvp ?? '';
    },
  },
  methods: {
    handleParticipantsListClick(eventData) {
      this.$emit('click', eventData);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.pick-participants__title {
  color: #f5f5f7;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 0.3px;
  text-align: center;
  margin-bottom: 8px;

  @include min-desktop() {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 12px;

    span {
      color: #979797;
    }
  }
}

.pick-participants__timer {
  text-align: center;
  color: #c64040;
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.3px;
  margin-bottom: 16px;

  @include min-tablet() {
    font-size: 32px;
    line-height: 1em;
    font-weight: 700;
    margin-bottom: 24px;
  }
}

.pick-participants__list {
  &:not(:last-child) {
    margin-bottom: 24px;

    @include min-desktop() {
      margin-bottom: 0;
    }
  }
}
</style>
